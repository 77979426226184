<template>
  <div v-loading="loading" class="w-full h-full py-2 overflow-scroll">
    <dynamic-scroller
      ref="parent-list"
      class="h-full outline-none"
      :items="list"
      :min-item-size="35"
      key-field="id"
      :buffer="800"
      :tabindex="0"
      @scroll="onScroll"
    >
      <template #default="{ item, active }">
        <dynamic-scroller-item
          :item="item"
          :active="active"
          :data-index="item.id"
        >
          <button
            :class="[
              'w-full text-left leading-normal px-4 py-2 text-sm cursor-pointer outline-none hover:bg-th-select-option-hover',
              modelValue && modelValue === (item[keyField] || item[labelField])
                ? 'font-semibold text-th-primary-blue bg-th-select-option-hover'
                : 'text-th-select-option-text'
            ]"
            :tabindex="0"
            type="button"
            @click.stop="$emit('select', item)"
            @mousedown.prevent
          >
            <div class="flex">
              <div
                class="flex-grow whitespace-pre"
                v-text="item.computed_name || item[labelField]"
              />
              <div
                v-if="item.computed_description"
                class="text-th-primary-gray truncate ml-1"
                v-text="item.computed_description"
              />
            </div>
          </button>
        </dynamic-scroller-item>
      </template>
    </dynamic-scroller>
  </div>
</template>

<script>
import { DynamicScroller, DynamicScrollerItem } from 'vue3-virtual-scroller'

const PAGINATION_BUFFER = 100

export default {
  name: 'VirtualList',
  components: {
    DynamicScroller,
    DynamicScrollerItem
  },
  props: {
    modelValue: {
      type: String,
      required: false,
      default: undefined
    },
    list: {
      type: Array,
      default: () => []
    },
    keyField: {
      type: String,
      default: 'id'
    },
    labelField: {
      type: String,
      default: 'name'
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onScroll(event) {
      if (
        event.target.scrollTop +
          event.target.offsetHeight +
          PAGINATION_BUFFER >=
        event.target.scrollHeight
      ) {
        this.$emit('paginate')
      }
    }
  }
}
</script>
