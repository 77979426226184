<template>
  <search-select
    :id="id"
    :model-value="localValue"
    :list="locations"
    :loading="loadingLocations"
    :computed-fields="computedFields"
    @resource-set="localValue = $event"
    @clear="localValue = null"
  />
</template>

<script>
import th from '@tillhub/javascript-sdk'
import SearchSelect from '@/components/select/search'

export default {
  components: {
    SearchSelect
  },
  props: {
    id: {
      type: String,
      default: 'id'
    },
    modelValue: {
      type: String,
      default: null
    },
    filterLocations: {
      type: Function,
      default: null
    },
    computedFields: {
      type: Array,
      default: () => ['label']
    }
  },
  data() {
    return {
      locations: [],
      loadingLocations: false
    }
  },
  computed: {
    localValue: {
      get() {
        return this.modelValue
      },
      set(location = {}) {
        this.$emit('update:modelValue', location.id)
      }
    }
  },
  async mounted() {
    this.fetchLocations()
  },
  methods: {
    async fetchLocations() {
      try {
        this.loadingLocations = true
        const { data = [] } = await th
          .stocks()
          .getLocations({ query: { deleted: false } })

        this.locations = this.filterLocations
          ? data.filter(this.filterLocations)
          : data
      } catch (err) {
        this.$logException(err, { trackError: false })
      } finally {
        this.loadingLocations = false
      }
    }
  }
}
</script>
