import placement from 'placement.js'
import { ref } from 'vue'
export default {
  setup(anchor, overlay, options, popperAppendToBody) {
    const placementValue = ref(options.placement)
    const elValue = ref(null)

    // Methods
    function create() {
      if (popperAppendToBody) {
        appendPopperToBody()
      }
      window.addEventListener('resize', this.computePlacement)
      window.addEventListener('scroll', this.computePlacement, true)
    }

    function destroy() {
      if (popperAppendToBody) {
        elValue.value.removeChild(overlay)
      }
      window.removeEventListener('resize', this.computePlacement)
      window.removeEventListener('scroll', this.computePlacement, true)
    }

    function computePlacement() {
      placement(anchor, overlay, options)
      placementValue.value = overlay.dataset.placement
    }

    function appendPopperToBody() {
      const el = document.getElementById('th-popper')
      if (el) {
        elValue.value = el
        elValue.value.appendChild(overlay)
      } else {
        elValue.value = document.createElement('div')
        elValue.value.setAttribute('id', 'th-popper')
        elValue.value.appendChild(overlay)
        document.body.appendChild(elValue.value)
      }
    }

    return {
      placementValue,
      elValue,
      anchor,
      overlay,
      options,
      popperAppendToBody,
      create,
      destroy,
      computePlacement,
      appendPopperToBody
    }
  }
}
